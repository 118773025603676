import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <Typography
        variant="h2"
        sx={{
          my: 2,
          textAlign: "center",
          fontFamily: "Outfit, sans-serif",
          fontSize: "30px",
          fontWeight: "700",
          lineHeight: "110%",
          textTransform: "capitalize",
        }}
      >
        FIND ME ON
      </Typography>
      <Typography
        variant="h6"
        sx={{
          my: 2,
          fontSize: "15px",
          color: "#333",
          textAlign: "center",
        }}
      >
        Feel free to connect with me on any of these platforms
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="animate__animated animate__backInUp"
      >
        <Button aria-label="twitter" href="https://twitter.com/Yassineamjad3">
          <TwitterIcon
            fontSize="large"
            sx={{
              color: "#00acee",
            }}
          />
        </Button>
        <Button aria-label="github" href="https://github.com/yassine20011">
          <GitHubIcon
            fontSize="large"
            sx={{
              color: "#171515",
            }}
          />
        </Button>
        <Button
          aria-label="linkedin"
          href="https://www.linkedin.com/in/yassine-amjad/"
        >
          <LinkedInIcon
            fontSize="large"
            sx={{
              color: "#0072b1",
            }}
          />
        </Button>
        <Button aria-label="mail" href="mailto:yassine.amjad001@gmail.com?subject=Let's Work Together">
          <EmailIcon
            fontSize="large"
            sx={{
              color: "#4285F4",
            }}
          />
        </Button>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              color: "#333",
              fontSize: "0.8em",
              fontWeight: "bold",
            }}
          >
            Designed and Developed by Yassine AMJAD
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              color: "#333",
              fontSize: "0.8em",
              fontWeight: "bold",
              my: 2,
            }}
          >
            Copyright © {currentYear}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Footer;
