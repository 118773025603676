import NavBar from "./components/Navbar";
import React  from "react";
import Whoami from "./components/whoami";
import About from "./components/about";
import Experience from "./components/experience";
import Footer from "./components/footer";
import Project from "./components/project";
import Education  from "./components/education";
import {BrowserRouter , Routes , Route} from 'react-router-dom'
import "./App.css";


const Admin = () => {
  return (
    <>
    <h1>Admin</h1>
    </>
  )
}

const HomePage = () => {
   
  return (
    <>
    <NavBar />
    <Whoami fullName="Yassine Amjad" job={["Web developer", "Engineer Student"]} />
    <About />
    <Experience />
    <Education />
    <Project />
    <Footer />
    </>
  )
}


const NotFound = () => {
  return (
    <>
      <div 
      style={
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        }
      }
      >
        <h1>Oops! Page not found.</h1>
        <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
        <a href="your-cv.pdf" download class="cv-button">Download My CV and Unlock the Secrets of My Awesomeness!</a>
    </div>
    </>
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />      
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}


export default App;