import { languages } from "../assets/languages/icons";
import { skills } from "../assets/skills/icons";
import "../assets/css/experience.css";
import { v4 as uuidv4 } from "uuid";

export default function Experience() {

  const jobs = [
    {
      id: uuidv4(),
      company: "Inventiv IT",
      employmentType: "Internship",
      title: "Frontend Developer",
      location: "Casablanca, Morocco",
      locationType: "Hybrid",
      date: "17 jul 2023 - Present",
      description: [
        "Throughout my internship, I've assumed the position of a Frontend Developer, Additionally, I've expanded my skillset to encompass backend responsibilities, employing technologies like PHP, Symfony, Sylius, and Docker. I've been actively engaged in the practical establishment of fundamental services like AWS RDS and Amazon S3, effectively displaying my capability to navigate intricate technical obstacles.",

        "One of my notable strengths lies in my meticulous documentation approach. Over the course of my journey, I've consistently utilized Atlassian tools to systematically record all procedures. This unwavering dedication has culminated in the generation of comprehensive and methodically structured records, thereby facilitating seamless collaboration and the dissemination of knowledge within teams.",
      ],
      skills: [
        "PHP",
        "Symfony Framework",
        "Docker Products",
        "Confluence",
        "Gitlab",
        "Amazon S3",
        "Amazon Relational Database Service (RDS)",
        "Full-Stack Development",
      ],
    }
  ]
  

  return (
    <>
    
      <div className="experience-section" id="experience">
        <h3>Experience</h3>
        <div className="experience-line">
          {jobs.map((job) => (
            <div className="experience-container" key={job.id}>
              <div className="experience-circle"></div>
              <div className="experience-content">
                <div className="experience-title">{job.title}</div>
                <div className="experience-subtitle">{job.company}  - {job.employmentType}</div>
                <div className="experience-subtitle">{job.date}</div>
                <div className="experience-subtitle">{job.location} - {job.locationType}</div>
                <ul className="experience-list">
                  {job.description.map((line) => (
                    <li className="experience-item" key={uuidv4()}>
                      {line}
                    </li>
                  ))}
                </ul>
                <div className="experience-subtitle"><strong>Skills:</strong></div>
                <ul className="experience-list">
                  {job.skills.map((skill) => (
                    <li className="experience-item" key={uuidv4()}>
                      {skill}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="icon-section">
        <h3 className="skillset-title animate__animated animate__backInLeft">
          Skillset
        </h3>
        <div className="icon-line">
          {Object.keys(languages).map((key) => (
            <div className="icon-container" key={key}>
              <img src={languages[key]} alt={key} className="icon" />
              <span className="icon-tooltip">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="icon-section">
        <h3>Tools and Technologies</h3>
        <div className="icon-line">
          {Object.keys(skills).map((key) => (
            <div className="icon-container" key={key}>
              <img src={skills[key]} alt={key} className="icon" />
              <span className="icon-tooltip">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
