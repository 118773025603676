import React from "react";
import Grid from "@mui/material/Grid";

const data = [
  {
    title: "EduQuiz",
    description:
      "A web application that allows teachers to create quizzes and students to answer them. ",
    url: "https://github.com/yassine20011/EduQuiz",
  },
  {
    title: "Py-proxy-checker",
    description:
      "A library that checks if a proxy is working or not using python.",
    url: "https://github.com/yassine20011/py-proxy-checker",
  },
  {
    title: "Extract Data From CV",
    description:
      "A web app that extracts data from a cv using a Machine Learning model. ",
    url: "https://github.com/yassine20011/extract_data_from_cv",
  },
  {
    title: "PDF to DOCX",
    description:
      "A simple web app that converts PDF to DOCX using Django Framework.",
    url: "https://github.com/yassine20011/webApp_convert_pdfToDocx",
  },
  {
    title: "Mass account creator",
    description:
      "A simple python script that creates multiple accounts on Instagram.",
    url: "https://github.com/yassine20011/MassAcounctsInstagram",
  },
  {
    title: "42 School",
    description:
      "A sequence of projects that I did during my pool at 42 school (1337).",
    url: "https://github.com/yassine20011/42",
  },

];

const Project = () => {

  return (
    <React.Fragment>

      <Grid container
        id="projects"
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontFamily: "Outfit, sans-serif",
              fontSize: "30px",
              fontWeight: "700",
              lineHeight: "110%",
              textTransform: "capitalize",
            }}
          >
            My Recent Works
            <hr />
          </h1>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 2, justifyContent: "center"}}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                  border: "1px solid #eaeaea",
                  padding: "20px",
                  textAlign: "center",
                  fontFamily: "Outfit, sans-serif",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
                <div>
                  <a
                    href={item.url}
                    style={{
                      textDecoration: "none",
                      color: "#6a61fa",
                      fontWeight: "bold",
                    }}
                  >
                    View Project
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default Project;