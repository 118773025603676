import bootstrap from './bootstrap.svg'
import c from "./c.svg"
import django from "./django.svg"
import flask from "./flask.svg"
import Javascript  from "./javascript.svg"
import materialui from "./materialui.svg"
import mySql from "./mySql.svg"
import python from "./python.svg"
import react from "./react.svg"
import typeScript from "./typeScript.svg"
import php from "./php.svg"



export const languages = {
    c: c,
    django: django,
    Javascript: Javascript,
    typeScript: typeScript,
    python: python,
    php: php,
    flask: flask,
    react: react,
    materialui: materialui,
    bootstrap: bootstrap,
    mySql: mySql,
}