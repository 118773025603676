import cloudflare from "./cloudflare.svg"
import figma from "./figma.svg"
import git from "./git.svg"
import gitHub from "./gitHub.svg"
import linode from "./linode.svg"
import vscode from "./vscode.svg"
import confluence from "./confluence.svg"
import aws from "./aws.svg"


export const skills = {
    vscode: vscode,
    git: git,
    gitHub: gitHub,
    aws: aws,
    confluence: confluence,
    figma: figma,
    linode: linode,
    cloudflare: cloudflare,
}